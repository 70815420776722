export default {

  en() {
    return {
      administeredBy: 'This service is administered by Refund Protect.',
      andManyMore: 'And many more...',
      covidRefund:' COVID-19 infection, isolation and family illness – <a role="button" href="https://refundable.me/covid/" target="_blank" aria-label="This service is administered by Refund Protect. Click here to find out more about the COVID-19 refund policy (opens in a new tab)">see details</a>',
      emergencyRecall: 'Emergency services recall',
      homeEmergency: 'Home emergency',
      weDontRefundCovid:'We do not refund for any travel restriction associated with COVID-19, or fear of travelling.',
      nonRefundableBooking: 'Non-refundable Booking',
      preExistingCondition: 'Pre-existing medical conditions',
      privateVehicleFailure: 'Private vehicle failure',
      publicTransportFailure: 'Public transport failure',
      recommended: 'Recommended',
      refundableBooking: 'Refundable Booking',
      relativeSickness: 'Immediate relative sickness',
      upgradeStatement: 'Upgrade your booking and receive a 100% refund (up to $15,000 USD) if you cannot attend for one of the many reasons in our Terms and Conditions <a role="button" href=" https://www.refundable.me/quint-events/" target="_blank" aria-label="Click here to read the Terms and Conditions for Refundable Booking (opens in a new tab)">here</a>, which you accept when you select a Refundable Booking',
      upgrade20Statement: 'Upgrade your booking and receive a 100% refund (up to $20,000 USD) if you cannot attend for one of the many reasons in our Terms and Conditions <a role="button" href=" https://www.refundable.me/quint-events/" target="_blank" aria-label="Click here to read the Terms and Conditions for Refundable Booking (opens in a new tab)">here</a>, which you accept when you select a Refundable Booking',
      unexpectedPersonalProblem: 'Sickness, accident, injury'
    }
  },
  ar() {
    return {
      administeredBy: '.الشركة الرائدة في حماية استرجاع الأموال Refund Protect تُدَارُ هذه الخدمة تحت إشراف',
      andManyMore: 'قم بترقية حجزك واسترجع أموالك بنسبة 100٪ (يمكن ان يصل المبلغ إلى 15000 دولار أمريكيّ) إذا لم تتمكّن من الحضور لأحد الأسباب العديدة الواردة في الشروط والأحكام الخاصّة بنا <a role="button" href = "https://www.refundable.me/quint-events/" target = "_ blank"> هنا </a> ،والّتي توافق عليها عند تحديد حجز قابل للاسترجاع',
      covidRefund: 'والحجر الصحّي ومرض أفراد العائلة COVID-19 عدوى – <a role="button" href="https://refundable.me/covid/" target="_blank" aria-label="This service is administered by Refund Protect. Click here to find out more about the COVID-19 refund policy (opens in a new tab)">اطّلع على التفاصيل</a>',
      emergencyRecall: 'حالات الطوارئ المنزليّة',
      homeEmergency: 'حالات الطوارئ المنزليّة',
      weDontRefundCovid: 'الحجر الصحّي ومرض العائلة ،COVID-19 عدوى– <a role="button" href="https://refundable.me/covid/">اُنظر التفاصيل</a>',
      nonRefundableBooking: 'حجز مقابله غير قابل للاسترجاع',
      preExistingCondition: 'الظروف الطبيّة الموجودة مسبقًا',
      privateVehicleFailure: 'عطب في السيّارة الخاصّة',
      publicTransportFailure: 'عطل في شبكة النقل الجماعيّ',
      recommended: 'اختر شروط وطريقة الدفع الخاصّة بك',
      refundableBooking: 'حجز قابل للاسترجاع',
      relativeSickness: 'مرض حاليّ ذو علاقة ',
      upgradeStatement: 'أكّد كلمة العبور',
      upgrade20Statement: 'حيّن حجزك واُحْصل على استرجاع 100٪ (قد يصل إلى 20000 دولار أمريكيّ) في حال تعذّر عليك الحضور لأحد الأسباب العديدة الواردة في الشروط والأحكام الخاصّة بنا <a role="button" href=" https://www.refundable.me/quint-events/" target="_blank" aria-label="Click here to read the Terms and Conditions for Refundable Booking (opens in a new tab)">هنا</a>, الّتي توافق عليها عند اختيار حجز قابل للاسترجاع',
      unexpectedPersonalProblem: 'مرض، حادث، إصابة',
    }
  },
  de() {
    return {
      administeredBy: 'Dieser Service wird von Refund Protect betrieben.',
      andManyMore: 'Und viele mehr...',
      covidRefund: 'COVID-19 Infektion, Isolation und Erkrankung in der Familie - <a role="button" href="https://refundable.me/covid/" target="_blank" aria-label="This service is administered by Refund Protect. Click here to find out more about the COVID-19 refund policy (opens in a new tab)">Details ansehen</a>',
      emergencyRecall: 'Rückruf von Notfalldiensten',
      homeEmergency: 'Notfall zu Hause',
      weDontRefundCovid: 'Bei Reisebeschränkungen oder Bedenken vor Reisen in Zusammenhang mit COVID-19 werden keine Kosten erstattet.',
      nonRefundableBooking: 'Nicht erstattungsfähige Buchung',
      preExistingCondition: 'Vorerkrankungen',
      privateVehicleFailure: 'Ausfall des Privatfahrzeugs',
      publicTransportFailure: 'Ausfall der öffentlichen Verkehrsmittel',
      recommended: 'Empfohlen',
      refundableBooking: 'Erstattungsfähige Buchungen',
      relativeSickness: 'Krankheit eines unmittelbaren Familienangehörigen',
      upgradeStatement: 'Upgraden Sie Ihre Buchung und erhalten Sie eine 100%ige Rückerstattung (bis zu USD 15.000), wenn Sie aus einem der zahlreichen Gründe, die in unseren Allgemeinen Geschäftsbedingungen <a role="button" href=" https://www.refundable.me/quint-events/" target="_blank" aria-label="Click here to read the Terms and Conditions for Refundable Booking (opens in a new tab)">hier</a> aufgeführt sind, nicht teilnehmen können. Sie akzeptieren diese AGB, wenn Sie eine erstattungsfähige Buchung wählen.',
      upgrade20Statement: 'Upgraden Sie Ihre Buchung und erhalten Sie eine 100%ige Rückerstattung (bis zu $20.000 USD), wenn Sie aus einem der zahlreichen Gründe, die in unseren Allgemeinen Geschäftsbedingungen <a role="button" href=" https://www.refundable.me/quint-events/" target="_blank" aria-label="Click here to read the Terms and Conditions for Refundable Booking (opens in a new tab)">hier</a> aufgeführt sind und die Sie bei der Auswahl einer rückzahlbaren Buchung akzeptieren, nicht an der Veranstaltung teilnehmen können.',
      unexpectedPersonalProblem: 'Krankheit, Unfall, Verletzung',
    }
  },
  enGB() {
    return {
      administeredBy: 'This service is administered by Refund Protect.',
      andManyMore: 'And many more...',
      covidRefund: ' COVID-19 infection, isolation and family illness – <a role="button" href="https://refundable.me/covid/" target="_blank" aria-label="This service is administered by Refund Protect. Click here to find out more about the COVID-19 refund policy (opens in a new tab)">see details</a>',
      emergencyRecall: 'Emergency services recall',
      homeEmergency: 'Home emergency',
      weDontRefundCovid: 'We do not refund for any travel restriction associated with COVID-19, or fear of travelling.',
      nonRefundableBooking: 'Non-refundable Booking',
      preExistingCondition: 'Pre-existing medical conditions',
      privateVehicleFailure: 'Private vehicle failure',
      publicTransportFailure: 'Public transport failure',
      recommended: 'Recommended',
      refundableBooking: 'Refundable Booking',
      relativeSickness: 'Immediate relative sickness',
      upgradeStatement: 'Upgrade your booking and receive a 100% refund (up to $15,000 USD) if you cannot attend for one of the many reasons in our Terms and Conditions <a role="button" href=" https://www.refundable.me/quint-events/" target="_blank" aria-label="Click here to read the Terms and Conditions for Refundable Booking (opens in a new tab)">here</a>, which you accept when you select a Refundable Booking',
      upgrade20Statement: 'Upgrade your booking and receive a 100% refund (up to $20,000 USD) if you cannot attend for one of the many reasons in our Terms and Conditions <a role="button" href=" https://www.refundable.me/quint-events/" target="_blank" aria-label="Click here to read the Terms and Conditions for Refundable Booking (opens in a new tab)">here</a>, which you accept when you select a Refundable Booking',
      unexpectedPersonalProblem: 'Sickness, accident, injury',
    }
  },
  esMX() {
    return {
      administeredBy: 'Este servicio es administrado por Refund Protect.',
      andManyMore: 'Y muchos más...',
      covidRefund: 'Contagio por COVID-19, cuarentena y enfermedad familiar – <a role="button" href="https://refundable.me/covid/" target="_blank" aria-label="This service is administered by Refund Protect. Click here to find out more about the COVID-19 refund policy (opens in a new tab)">ver detalles</a>',
      emergencyRecall: 'Revocación de los servicios de emergencia',
      homeEmergency: 'Emergencia en el hogar',
      weDontRefundCovid: 'No reembolsamos ninguna restricción de viaje asociada con COVID-19 o miedo a viajar.',
      nonRefundableBooking: 'Reserva No Reembolsable',
      preExistingCondition: 'Condiciones médicas preexistentes',
      privateVehicleFailure: 'Fallo de Vehículo Privado',
      publicTransportFailure: 'Fallo del Transporte Público',
      recommended: 'Recomendado',
      refundableBooking: 'Reservas reembolsables',
      relativeSickness: 'Enfermedad de familiar inmediato',
      upgradeStatement: 'Elija un upgrade y reciba un reembolso del 100% (hasta $15,000 USD) si no puede asistir por alguno de los muchos motivos que se describen en nuestros Términos y condiciones <a role="button" href=" https://www.refundable.me/quint-events/" target="_blank" aria-label="Click here to read the Terms and Conditions for Refundable Booking (opens in a new tab)">aquí descritos</a>, que acepta cuando selecciona una Reserva Reembolsable.',
      upgrade20Statement: 'Upgrade your booking and receive a 100% refund (up to $20,000 USD) if you cannot attend for one of the many reasons in our Terms and Conditions <a role="button" href=" https://www.refundable.me/quint-events/" target="_blank" aria-label="Click here to read the Terms and Conditions for Refundable Booking (opens in a new tab)">here</a>, which you accept when you select a Refundable Booking',
      unexpectedPersonalProblem: 'Enfermedad, accidente, lesión',
    }
  },
  es() {
    return {
      administeredBy: 'This service is administered by Refund Protect.',
      andManyMore: 'Y muchos más...',
      covidRefund: 'COVID-19 infección, aislamiento y enfermedad familiar – <a role="button" href="https://refundable.me/covid/" target="_blank" aria-label="This service is administered by Refund Protect. Click here to find out more about the COVID-19 refund policy (opens in a new tab)">ver detalles</a>',
      emergencyRecall: 'Retirada de los servicios de emergencia',
      homeEmergency: 'Emergencia en el hogar',
      weDontRefundCovid: 'No reembolsamos por ninguna restricción de viaje asociada a COVID-19, ni por miedo a viajar.',
      nonRefundableBooking: 'Reserva No Reembolsable',
      preExistingCondition: 'Condiciones médicas previas',
      privateVehicleFailure: 'Fallo del vehículo privado',
      publicTransportFailure: 'Fallo del transporte público',
      recommended: 'Recomendado',
      refundableBooking: 'Reserva Reembolsable',
      relativeSickness: 'Enfermedad de un familiar directo',
      upgradeStatement: 'Mejora tu reserva y recibe un reembolso del 100% (hasta $15.000 USD) si no puedes asistir por una de las muchas razones que aparecen en nuestros Términos y Condiciones <a role="button" href=" https://www.refundable.me/quint-events/" target="_blank" aria-label="Click here to read the Terms and Conditions for Refundable Booking (opens in a new tab)">aquí</a>, que aceptas cuando seleccionas una Reserva Reembolsable',
      upgrade20Statement: 'Mejora tu reserva y recibe un reembolso del 100% (hasta $20.000 USD) si no puedes asistir por una de las muchas razones que aparecen en nuestros Términos y Condiciones <a role="button" href=" https://www.refundable.me/quint-events/" target="_blank" aria-label="Click here to read the Terms and Conditions for Refundable Booking (opens in a new tab)">aquí</a>, que aceptas cuando seleccionas una Reserva Reembolsable',
      unexpectedPersonalProblem: 'Enfermedad, accidente, lesión',
    }
  },
  fr() {
    return {
      administeredBy: 'Ce service est administré par Refund Protect.',
      andManyMore: 'Et bien plus encore...',
      covidRefund: 'Infection COVID-19, isolation et maladie familiale – <a role="button" href="https://refundable.me/covid/" target="_blank" aria-label="This service is administered by Refund Protect. Click here to find out more about the COVID-19 refund policy (opens in a new tab)">voir les détails</a>',
      emergencyRecall: 'Rappel des services d\'urgence',
      homeEmergency: 'Urgence domestique',
      weDontRefundCovid: 'Nous n\'effectuerons aucun remboursement dans le cas de restrictions de déplacement liées à la COVID-19, ou de craintes de voyager.',
      nonRefundableBooking: 'Réservation non remboursable',
      preExistingCondition: 'Affections préexistantes',
      privateVehicleFailure: 'Défaillance d\'un transport privé',
      publicTransportFailure: 'Défaillance d\'un transport en commun',
      recommended: 'Recommandé',
      refundableBooking: 'Réservation remboursable',
      relativeSickness: 'Maladie d\'un parent proche',
      upgradeStatement: 'Surclassez votre réservation et recevez un remboursement allant jusqu\'à 100 % (pour un maximum de 15 000 $ US) si vous deviez vous trouver dans l\'impossibilité d\'assister à l\'événement pour l\'une des nombreuses raisons détaillées <a role="button" href=" https://www.refundable.me/quint-events/" target="_blank" aria-label="Click here to read the Terms and Conditions for Refundable Booking (opens in a new tab)">ici</a> dans nos conditions d\'utilisation, que vous avez acceptées en choisissant une réservation remboursable.',
      upgrade20Statement: 'Surclassez votre réservation et recevez un remboursement intégral (jusqu\'à 20 000 $ US) si vous deviez vous retrouver incapable d\'assister à un événement du fait de l\'une des nombreuses raisons listées dans nos conditions d\'utilisation <a role="button" href=" https://www.refundable.me/quint-events/" target="_blank" aria-label="Click here to read the Terms and Conditions for Refundable Booking (opens in a new tab)">ici</a>, que vous acceptez lorsque vous choisissez une réservation remboursable',
      unexpectedPersonalProblem: 'Maladie, accident, blessure',
    }
  },
  it() {
    return {
      administeredBy: 'This service is administered by Refund Protect.',
      andManyMore: 'E tanti altri ancora…',
      covidRefund: 'COVID-19, isolamento e malattia in famiglia – <a role="button" href="https://refundable.me/covid/" aria-label="This service is administered by Refund Protect. Click here to find out more about the COVID-19 refund policy (opens in a new tab)">leggi i dettagli</a>',
      emergencyRecall: 'Richiamo dei servizi di emergenza',
      homeEmergency: 'Emergenza domestica',
      weDontRefundCovid: 'Non concediamo rimborsi causati da restrizioni di viaggio legate al COVID-19 o dalla paura di mettersi in viaggio.',
      nonRefundableBooking: 'Prenotazione non rimborsabile',
      preExistingCondition: 'Condizioni mediche preesistenti',
      privateVehicleFailure: 'Guasto a veicolo privato',
      publicTransportFailure: 'Problemi di trasporto pubblico',
      recommended: 'Consigliato',
      refundableBooking: 'Prenotazione rimborsabile',
      relativeSickness: 'Malattia di parente prossimo',
      upgradeStatement: 'Fai l\'upgrade della tua prenotazione e ricevi un rimborso pari al 100% (fino a un massimo di 15.000 $) in caso di mancata partecipazione per uno dei tanti motivi elencati in "Termini e condizioni" <a role="button" href=" https://www.refundable.me/quint-events/" target="_blank" aria-label="Click here to read the Terms and Conditions for Refundable Booking (opens in a new tab)">a questo indirizzo</a>, e che accetti nel momento in cui selezioni una prenotazione rimborsabile',
      upgrade20Statement: 'Fai l\'upgrade della tua prenotazione e ricevi un rimborso pari al 100% (fino a un massimo di 20.000 USD) in caso di mancata partecipazione per uno dei tanti motivi elencati in "Termini e condizioni" <a role="button" href=" https://www.refundable.me/quint-events/" target="_blank" aria-label="Click here to read the Terms and Conditions for Refundable Booking (opens in a new tab)">a questo indirizzo</a>, e che accetti nel momento in cui selezioni una prenotazione rimborsabile',
      unexpectedPersonalProblem: 'Malattia, infortunio, lesioni',
    }
  },
  ja() {
    return {
      administeredBy: 'このサービスは Refund Protect によって管理されています。',
      andManyMore: 'など.....',
      covidRefund: 'COVID-19 感染、隔離、家族の病気 – <a role="button" href="https://refundable.me/covid/" target="_blank" aria-label="This service is administered by Refund Protect. Click here to find out more about the COVID-19 refund policy (opens in a new tab)">詳細を見る</a>',
      emergencyRecall: '緊急サービスについて',
      homeEmergency: '自宅の緊急事態',
      weDontRefundCovid: 'COVID-19に関連した旅行制限、または旅行への懸念については返金しません。',
      nonRefundableBooking: '返金不可の予約',
      preExistingCondition: '事前の健康状態',
      privateVehicleFailure: '自家用車の不具合',
      publicTransportFailure: '公共交通機関の不具合',
      recommended: '推奨',
      refundableBooking: '返金可能な予約',
      relativeSickness: '近親者の病気',
      upgradeStatement: '<a role="button" href="https://www.refundable.me/quint-events/" target="_blank" aria-label="Click here to read the Terms and Conditions for Refundable Booking (opens in a new tab)">コチラ</a>にある利用規約中に記載されている理由のいずれかで参加できない場合は、予約をアップグレードすると。100% 払い戻し (最大15,000 米ドル) を受け取れます。これは払い戻し可能な予約を選択する場合に同意したことになります。',
      upgrade20Statement: '払い戻し可能な予約を選択したときに同意した、利用規約に記載されている多くの理由のいずれかで参加できない場合は、予約をアップグレードして 100%の払い戻し (最大 20,000 米ドル) を <a role="button" href=" https://www.refundable.me/quint-events/" target="_blank" aria-label="Click here to read the Terms and Conditions for Refundable Booking (opens in a new tab)">こちら</a>で受け取りましょう。',
      unexpectedPersonalProblem: '病気、事故、けが',
    }
  },
  pl() {
    return {
      administeredBy: 'This service is administered by Refund Protect.',
      andManyMore: 'And many more...',
      covidRefund: ' COVID-19 infection, isolation and family illness – <a role="button" href="https://refundable.me/covid/" target="_blank" aria-label="This service is administered by Refund Protect. Click here to find out more about the COVID-19 refund policy (opens in a new tab)">see details</a>',
      emergencyRecall: 'Emergency services recall',
      homeEmergency: 'Home emergency',
      weDontRefundCovid: 'We do not refund for any travel restriction associated with COVID-19, or fear of travelling.',
      nonRefundableBooking: 'Non-refundable Booking',
      preExistingCondition: 'Pre-existing medical conditions',
      privateVehicleFailure: 'Private Vehicle Failure',
      publicTransportFailure: 'Public Transport Failure',
      recommended: 'Recommended',
      refundableBooking: 'Refundable Booking',
      relativeSickness: 'Immediate relative sickness',
      upgradeStatement: 'Upgrade your booking and receive a 100% refund (up to $15,000 USD) if you cannot attend for one of the many reasons in our Terms and Conditions <a role="button" href=" https://www.refundable.me/quint-events/" target="_blank" aria-label="Click here to read the Terms and Conditions for Refundable Booking (opens in a new tab)">here</a>, which you accept when you select a Refundable Booking',
      upgrade20Statement: 'Upgrade your booking and receive a 100% refund (up to $20,000 USD) if you cannot attend for one of the many reasons in our Terms and Conditions <a role="button" href=" https://www.refundable.me/quint-events/" target="_blank" aria-label="Click here to read the Terms and Conditions for Refundable Booking (opens in a new tab)">here</a>, which you accept when you select a Refundable Booking',
      unexpectedPersonalProblem: 'Sickness, accident, injury',
    }
  },
  po() {
    return {
      administeredBy: 'This service is administered by Refund Protect.',
      andManyMore: 'And many more...',
      covidRefund: ' COVID-19 infection, isolation and family illness – <a role="button" href="https://refundable.me/covid/" target="_blank" aria-label="This service is administered by Refund Protect. Click here to find out more about the COVID-19 refund policy (opens in a new tab)">see details</a>',
      emergencyRecall: 'Emergency services recall',
      homeEmergency: 'Home emergency',
      weDontRefundCovid: 'We do not refund for any travel restriction associated with COVID-19, or fear of travelling.',
      nonRefundableBooking: 'Non-refundable Booking',
      preExistingCondition: 'Pre-existing medical conditions',
      privateVehicleFailure: 'Private vehicle failure',
      publicTransportFailure: 'Public transport failure',
      recommended: 'Recommended',
      refundableBooking: 'Refundable Booking',
      relativeSickness: 'Immediate relative sickness',
      upgradeStatement: 'Upgrade your booking and receive a 100% refund (up to $15,000 USD) if you cannot attend for one of the many reasons in our Terms and Conditions <a role="button" href=" https://www.refundable.me/quint-events/" target="_blank" aria-label="Click here to read the Terms and Conditions for Refundable Booking (opens in a new tab)">here</a>, which you accept when you select a Refundable Booking',
      upgrade20Statement: 'Upgrade your booking and receive a 100% refund (up to $20,000 USD) if you cannot attend for one of the many reasons in our Terms and Conditions <a role="button" href=" https://www.refundable.me/quint-events/" target="_blank" aria-label="Click here to read the Terms and Conditions for Refundable Booking (opens in a new tab)">here</a>, which you accept when you select a Refundable Booking',
      unexpectedPersonalProblem: 'Sickness, accident, injury',
    }
  },
  zhCN() {
    return {
      administeredBy: 'This service is administered by Refund Protect.',
      andManyMore: '以及更多……',
      covidRefund: '新型冠状病毒感染、隔离和家庭疾病——<a role="button" href="https://refundable.me/covid/" target="_blank" aria-label="This service is administered by Refund Protect. Click here to find out more about the COVID-19 refund policy (opens in a new tab)">查看详情</a>',
      emergencyRecall: '紧急服务召回',
      homeEmergency: '家庭急救护理',
      weDontRefundCovid: '我们不会办理因 COVID-19 相关的任何旅行限制或出行的担心而导致的退款。',
      nonRefundableBooking: '不可退款的预订',
      preExistingCondition: '过往病史',
      privateVehicleFailure: '私家车故障',
      publicTransportFailure: '公共交通故障',
      recommended: '推荐',
      refundableBooking: '可退款预订',
      relativeSickness: '直接相关疾病',
      upgradeStatement: '如果您由于我们<a role="button" href=" https://www.refundable.me/quint-events/" target="_blank" aria-label="Click here to read the Terms and Conditions for Refundable Booking (opens in a new tab)">此处</a>条款和条件（您在选择可退款预订时接受的条款和条件）中的某个原因不能参加，请升级您的预订，即可获得全额退款（最高15,000美金）',
      upgrade20Statement: '如果您由于我们<a role="button" href=" https://www.refundable.me/quint-events/" target="_blank" aria-label="Click here to read the Terms and Conditions for Refundable Booking (opens in a new tab)">此处</a>条款和条件（您在选择可退款预订时接受的条款和条件）中的某个原因不能参加，请升级您的预订，即可获得全额退款（最高20,000美金）',
      unexpectedPersonalProblem: '疾病、事故、受伤',
    }
  },
}
