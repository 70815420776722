export default {
  en() {
    return {
      click_to_copy: 'Click to Copy Code',
      copied_code: 'Copied {code}',
      copy_failed: 'Failed to copy code',
      copy_failed_permissions: 'Cannot copy due to browser permissions',
      f1_access_codes: 'F1 TV Access Codes',
      instructions_link: 'Below is a list of your F1 TV Access codes. Instructions on how to redeem the codes can be <a role="button" href={link} target="_blank" aria-label="Click here to read instructions on how to redeem F1 TV Access codes (opens in a new tab)">found here</a>'
    }
  },
  ar() {
    return {
      click_to_copy: ' اُنْقر لنسخ الرمز/الشفرة',
      copied_code: 'منسوخ {code}',
      copy_failed: 'فشلت عمليّة نسخ الرمز',
      copy_failed_permissions: 'لا يمكن النسخ بسبب أذونات متصفّح الانترنت',
      f1_access_codes: 'F1 شفرات الوصول إلى تلفزيون ',
      instructions_link: ' يمكن أن تكون التعليمات حول كيفيّة استرجاع الرموز .F1 TV فيما يلي قائمة برموز الوصول الخاصّة بك لـ <a role="button" href={link} target="_blank" aria-label="Click here to read instructions on how to redeem F1 TV Access codes (opens in a new tab)">موجود هنا</a>',
    }
  },
  de() {
    return {
      click_to_copy: 'Klicken um den Code zu kopieren',
      copied_code: '{code} kopiert',
      copy_failed: 'Code konnte nicht kopiert werden',
      copy_failed_permissions: 'Kann aufgrund von Browser-Berechtigungen nicht kopiert werden.',
      f1_access_codes: 'F1 TV Access Codes',
      instructions_link: 'Nachfolgend finden Sie eine Liste Ihrer F1 TV Access Codes. Anweisungen zum Einlösen der Codes <a role="button" href={link} target="_blank" aria-label="Click here to read instructions on how to redeem F1 TV Access codes (opens in a new tab)">finden Sie hier</a>.',
    }
  },
  enGB() {
    return {
      click_to_copy: 'Click to Copy Code',
      copied_code: 'Copied {code}',
      copy_failed: 'Failed to copy code',
      copy_failed_permissions: 'Cannot copy due to browser permissions',
      f1_access_codes: 'F1 TV Access Codes',
      instructions_link: 'Below is a list of your F1 TV Access codes. Instructions on how to redeem the codes can be <a role="button" href={link} target="_blank" aria-label="Click here to read instructions on how to redeem F1 TV Access codes (opens in a new tab)">found here</a>',
    }
  },
  esMX() {
    return {
      click_to_copy: 'Haga clic para copiar el código',
      copied_code: 'Copiado {code}',
      copy_failed: 'Copia de código falló',
      copy_failed_permissions: 'No se puede copiar debido a los permisos de su navegador',
      f1_access_codes: 'Códigos F1 TV Access',
      instructions_link: 'A continuación encontrará una lista de sus códigos F1 TV Access. Las instrucciones para redimir los códigos se pueden encontrar  <a role="button" href={link} target="_blank" aria-label="Click here to read instructions on how to redeem F1 TV Access codes (opens in a new tab)">aquí</a>',
    }
  },
  es() {
    return {
      click_to_copy: 'Haz clic para Copiar el Código',
      copied_code: 'Copiado {code}',
      copy_failed: 'Fallo al copiar el código',
      copy_failed_permissions: 'No se puede copiar debido a los permisos del navegador',
      f1_access_codes: 'Códigos de Acceso F1 TV',
      instructions_link: 'A continuación encontrarás una lista de tus códigos de Acceso F1 TV. Las instrucciones para canjear los códigos pueden ser <a role="button" href={link} target="_blank" aria-label="Click here to read instructions on how to redeem F1 TV Access codes (opens in a new tab)">encontradas aquí</a>',
    }
  },
  fr() {
    return {
      click_to_copy: 'Cliquez pour copier le code',
      copied_code: '{code} copié',
      copy_failed: 'La copie du code a échoué',
      copy_failed_permissions: 'Impossible de copier à cause des permissions du navigateur',
      f1_access_codes: 'Codes pour F1 TV Access',
      instructions_link: 'Vous trouverez ci-dessous votre liste de codes pour F1 TV Access. Vous trouverez les instructions sur la manière d\'utiliser vos codes <a role="button" href={link} target="_blank" aria-label="Click here to read instructions on how to redeem F1 TV Access codes (opens in a new tab)">ici</a>',
    }
  },
  it() {
    return {
      click_to_copy: 'Clicca per copiare il codice',
      copied_code: '{code} copiato',
      copy_failed: 'Impossibile copiare il codice',
      copy_failed_permissions: 'Impossibile copiare il codice a causa delle impostazioni di autorizzazione del tuo browser',
      f1_access_codes: 'Codici di accesso a F1 TV',
      instructions_link: 'Di seguito troverai un elenco dei tuoi codici di accesso a F1 TV. Le istruzioni su come fare per riscattare i codici di accesso si trovano <a role="button" href={link} target="_blank" aria-label="Click here to read instructions on how to redeem F1 TV Access codes (opens in a new tab)">qui</a>',
    }
  },
  ja() {
    return {
      click_to_copy: 'クリックしてコードをコピー',
      copied_code: 'コピーしました{コード}',
      copy_failed: 'コードのコピーに失敗しました',
      copy_failed_permissions: 'ブラウザのパーミッションによってコピーできません',
      f1_access_codes: 'F1 TV アクセスコード',
      instructions_link: '下記は F1 TV アクセスのコード一覧です。コードの引き換え方については <a role="button" href={link} target="_blank" aria-label="Click here to read instructions on how to redeem F1 TV Access codes (opens in a new tab)">ここをご覧ください</a>',
    }
  },
  pl() {
    return {
      click_to_copy: 'kliknij, żeby skopiować kod',
      copied_code: 'skopiowano {code}',
      copy_failed: 'nieudana próba skopiowania kodu',
      copy_failed_permissions: '  przeglądarka nie zezwala na skopiowanie',
      f1_access_codes: 'kody do F1 TV Access',
      instructions_link: 'Poniżej jest lista kodów do F1 TV Access. Instrukcję postępowania znajdziesz <a role="button" href={link} target="_blank" aria-label="Click here to read instructions on how to redeem F1 TV Access codes (opens in a new tab)">tutaj</a> ',
    }
  },
  po() {
    return {
      click_to_copy: 'Click to Copy Code',
      copied_code: 'Copied {code}',
      copy_failed: 'Failed to copy code',
      copy_failed_permissions: 'Cannot copy due to browser permissions',
      f1_access_codes: 'F1 TV Access Codes',
      instructions_link: 'Below is a list of your F1 TV Access codes. Instructions on how to redeem the codes can be <a role="button" href={link} target="_blank" aria-label="Click here to read instructions on how to redeem F1 TV Access codes (opens in a new tab)">found here</a>',
    }
  },
  zhCN() {
    return {
      click_to_copy: '点击复制代码',
      copied_code: '已复制{code}',
      copy_failed: '复制代码失败',
      copy_failed_permissions: '由于浏览器权限无法复制',
      f1_access_codes: 'F1电视访问代码',
      instructions_link: '以下是您的F1电视访问代码列表。<a role="button" href={link} target="_blank" aria-label="Click here to read instructions on how to redeem F1 TV Access codes (opens in a new tab)">点击此处</a>查看代码兑换说明',
    }
  },
}
